import React, {useEffect, useState} from "react";
import CollapsibleItem from "../Components/Blocks/CollapsibleItem";
import {CiCircleInfo} from "react-icons/ci";
import {IoMdInformationCircleOutline, IoMdPricetag} from "react-icons/io";
import {IoPricetagOutline} from "react-icons/io5";
import {FiCheckSquare, FiPackage} from "react-icons/fi";
import ContactForm from "../Components/Blocks/ContactForm";
import LotterySlider from "../Components/Blocks/LotterySlider";

function LotteryPage() {
    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    return (
        <div className="flex flex-col mt-44 w-full text-center items-center justify-center font-bold">
            <h1 className="mb-5 text-white text-[20px] md:text-[28px] px-6">Soutěž každý den o jedinečné produkty z VendorsWorld!</h1>
            <div className="px-8 md:px-0 w-full md:w-1/2 my-4">
                <CollapsibleItem
                    icon={<IoMdInformationCircleOutline/>}
                    title={"Jak se mohu zúčastnit?"}
                    content={
                        <div>
                            <br/>
                            <p>Abyste se mohli zúčastnit našeho denního losování, stačí si
                                zakoupit <strong>předplatné</strong> za <strong>pouhých
                                    30 Kč</strong> měsíčně.
                                Tímto způsobem se automaticky zařadíte do <strong>denního losování</strong> o jedinečné
                                produkty, které nabízíme.
                            </p>
                            <br/>
                            <p>
                                Každý den <strong> losujeme výherce</strong>, kteří
                                získají <strong>ZDARMA</strong> produkty z naší nabídky.
                            </p>
                        </div>
                    }
                />
                <CollapsibleItem
                    icon={<FiPackage/>}
                    title={"Kam dorazí moje výhra?"}
                    content={
                        <div>
                            <br/>
                            <p>Po stisknutí tlačítka níže o vyplnění údajů pro měsíční předplatné musíte vyplnit také
                                vaši fakturační adresu.
                            </p>
                            <br/>

                            <p>Tuto fakturační adresu následně použijeme i k <strong>odeslání vaší výhry!</strong>
                            </p>
                            <br/>
                            <p>
                                Doručení výhry poté trvá <strong>2-3 dny</strong> na vaši adresu.
                            </p>
                        </div>
                    }
                />
                <CollapsibleItem
                    icon={<FiCheckSquare/>}
                    title={"Jak se dozvím, jestli jsem vyhrál?"}
                    content={
                        <div>
                            <p><br/>
                                Pokud jsme si Vás vybrali za výherce, na Váš <strong>e-mail a telefonní číslo</strong> zašleme <strong>oznámení
                                    o výhře</strong> a potvrzení o doručovací adrese.
                            </p>
                        </div>
                    }
                />
                <div className="mt-10">
                    <LotterySlider/>
                </div>
                <div className="mt-8 mb-2 flex items-center justify-center">
                    <button className="flex items-center">
                        <a
                            href="https://buy.stripe.com/dR63dx4as80k8cE4gh"
                            className="text-white bg-[#2563eb] hover:bg-blue-700 focus:ring-4 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Přidat se do denního losování
                        </a>
                    </button>
                </div>
                <div className="text-gray-300 text-center mb-8 text-[12px] font-normal">
                    <p>+ první 3 dny jsou zcela zdarma!</p>
                </div>
            </div>
        </div>
    );
}

export default LotteryPage;
