import React, {useContext, useEffect, useState} from "react";
import Obrazek from "../Components/Blocks/Obrazek";
import Reviews from "../Components/Reviews/Reviews";
import ReviewsSlider from "../Components/Reviews/ReviewsSlider";
import ImageWithTextGrid from "../Components/Blocks/ImageText";
import Card from "../Components/Blocks/Card";
import {useNavigate} from "react-router-dom";
import {CardContext} from "../Contexts/CardContext";
import CategoryPreview from "../Components/Blocks/CategoryPreview";
import LotterySlider from "../Components/Blocks/LotterySlider";

function Main() {
    const {cards} = useContext(CardContext);
    const navigate = useNavigate();

    const [displayCards, setDisplayCards] = useState([]);

    useEffect(() => {
        // Function to determine screen size and set the number of cards to display
        const updateDisplayCards = () => {
            const isMobile = window.innerWidth < 768; // Assume mobile if width < 768px
            const limit = isMobile ? 2 : 4;
            setDisplayCards(cards.slice(0, limit));
        };

        // Initial load
        updateDisplayCards();

        // Update on resize
        window.addEventListener('resize', updateDisplayCards);
        return () => window.removeEventListener('resize', updateDisplayCards);
    }, [cards]);

    useEffect(() => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
    }, []); // Empty dependency array ensures this runs only once when the component mounts

    return (
        <div className={"flex flex-col w-full mt-36 bg-black justify-center"}>
            <div className={"w-full md:px-0 px-4 md:w-3/5 flex flex-col mx-auto"}>


                <ImageWithTextGrid/>
                <div className="text-white font-semibold text-center mb-8 text-[28px] md:mt-0 mt-4">
                    <p>30Kč měsíčně = soutěž denně o velké ceny!</p>
                </div>
                <LotterySlider/>
                <div className="mt-4 mb-10 flex items-center justify-center">
                    <button
                        onClick={() => navigate("/losovani")}
                        className="text-white bg-[#2563eb] hover:bg-blue-700 focus:ring-4 focus:outline-none font-medium rounded-full text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                        Zúčastnit se soutěže
                    </button>
                </div>


                <CategoryPreview title={"Apple"} handle={"apple"}/>
                <CategoryPreview title={"Boty"} handle={"boty"}/>
                <CategoryPreview title={"Oblečení"} handle={"oblečení"}/>


                <div className={"text-white font-bold italic w-full text-center md:mt-0 mt-12"}>
                    <h1 className={"text-[20px] md:text-[24px]"}>🎉 JIŽ PŘES 2000+ SPOKOJENÝCH ZÁKAZNÍKŮ</h1>
                    <h3 className={"text-[15px] mb-4 mt-2"}> založeno v roce 2021 </h3>
                </div>

                <Reviews/>
                <ReviewsSlider/>
            </div>
        </div>
    );
}

export default Main;
