import React, { useState, useEffect } from "react";
import {useNavigate} from "react-router-dom";

const LotterySlider = () => {
    // Manually defined lottery winners information
    const winners = [
        {
            id: 1,
            title: "Gratulujeme!",
            content: "Jakub vyhrál 3x AirPods Pro 2",
            customerName: "01.04.2025",
            img: "https://cdn.whustle.com/vwairpodspro2.png",
        },
        {
            id: 2,
            title: "Gratulujeme!",
            content: "Jana vyhrála iPhone 15 Pro",
            customerName: "02.04.2025",
            img: "https://cdn.whustle.com/vwiphonenature.png",
        },
        {
            id: 3,
            title: "Gratulujeme!",
            content: "Ondřej vyhrál 2x AirPods Max",
            customerName: "03.04.2025",
            img: "https://cdn.whustle.com/vwmaxgreen1.png",
        },
        // Add additional winners manually here
    ];

    const navigate = useNavigate();
    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex < winners.length - 1 ? prevIndex + 1 : 0
        );
    };

    const prevSlide = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex > 0 ? prevIndex - 1 : winners.length - 1
        );
    };

    useEffect(() => {
        const interval = setInterval(() => {
            nextSlide();
        }, 5000);

        return () => clearInterval(interval);
    }, [currentIndex]);

    return (
        <div className="relative w-full max-w-screen-xl mx-auto h-full mt-4">
            {/* Left Arrow */}
            <button
                onClick={prevSlide}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 bg-black text-white p-2 rounded-full"
            >
                <span className="text-[30px]">&lt;</span>
            </button>

            {/* Lottery Winner Slider */}
            <div className="overflow-hidden h-full mx-8">
                <div
                    className="flex transition-transform duration-300 ease-in-out"
                    style={{ transform: `translateX(-${currentIndex * 100}%)` }}
                >
                    {winners.map((winner) => (
                        <div
                            key={winner.id}
                            className="flex-none w-full p-4 flex flex-col items-center justify-center space-y-4"
                        >
                            <div className="grid grid-cols-1 space-x-4 items-center text-center w-64">
                                <div className="flex flex-col items-center space-y-1.5">
                                    <h3 className="text-[16px] font-bold text-white">
                                        {winner.title}
                                    </h3>
                                    <p className="text-[16px] font-normal text-white">
                                        {winner.content}
                                    </p>
                                    <p className="text-[14px] text-[#4a4a4a] mt-1">
                                        {winner.customerName}
                                    </p>
                                    <img
                                        src={winner.img}
                                        alt={winner.customerName}
                                        className="w-28 h-28 object-cover rounded-full"
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Right Arrow */}
            <button
                onClick={nextSlide}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 bg-black text-white p-2 rounded-full"
            >
                <span className="text-[30px]">&gt;</span>
            </button>
        </div>
    );
};

export default LotterySlider;
