import React, { createContext, useState, useContext, useEffect } from "react";
import Cookies from "js-cookie";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false); // State for the drawer
    const [isInitialLoad, setIsInitialLoad] = useState(true); // Flag to prevent saving on initial load

    const totalValue = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);

    // Load cart items from cookies when the component mounts
    useEffect(() => {
        const savedCart = Cookies.get("cart-items");
        if (savedCart) {
            setCartItems(JSON.parse(savedCart));
        }
        setIsInitialLoad(false); // Mark the initial load as complete
    }, []);

    // Save cart items to cookies whenever the cart updates (but skip the initial load)
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const discountCode = params.get("discount");
        if (discountCode) {
            handleApplyCouponFromURL(discountCode); // Apply the coupon from the URL
        }
        if (!isInitialLoad) {
            Cookies.set("cart-items", JSON.stringify(cartItems), { expires: 7 }); // Expires in 7 days
        }
    }, [cartItems, isInitialLoad]);

    const handleApplyCouponFromURL = (couponCode) => {
        // Define valid coupons
        const validCoupons = {
            "SECRET2024": { discount: 10, code: "SECRET2024" },
            "KBK.WIFI": { discount: 10, code: "KBK.WIFI" },
            "GET20FF": {discount: 20, code: "GET20FF"}
        };

        if (validCoupons[couponCode]) {
            Cookies.set("applied-coupons", JSON.stringify([validCoupons[couponCode]]), { expires: 7 }); // Save for 7 days
        }
    };

    const areVariantsEqual = (variants1, variants2) => {
        if (!variants1 || !variants2) return false;
        const keys1 = Object.keys(variants1);
        const keys2 = Object.keys(variants2);
        if (keys1.length !== keys2.length) return false;
        return keys1.every((key) => variants1[key] === variants2[key]);
    };

    const addToCart = (item) => {
        if ((totalValue + item.price) >= 999999) return;
        if (item.stock < item.quantity) return;

        setCartItems((prevItems) => {
            const existingItemIndex = prevItems.findIndex(
                (cartItem) =>
                    cartItem.title === item.title &&
                    areVariantsEqual(cartItem.selectedOptions, item.selectedOptions)
            );

            if (existingItemIndex !== -1) {
                const updatedItems = [...prevItems];
                const existingItem = updatedItems[existingItemIndex];

                // Ensure stock is respected
                updatedItems[existingItemIndex] = {
                    ...existingItem,
                    quantity: Math.min(existingItem.quantity + item.quantity, existingItem.stock, 10),
                };
                return updatedItems;
            } else {
                // Add the new item
                return [
                    ...prevItems,
                    {
                        ...item,
                        quantity: item.quantity || 1,
                        stock: item.stock || 10, // Default stock if missing
                    },
                ];
            }
        });

        setIsDrawerOpen(true);
    };

    const increaseQuantity = (item) => {
        if ((totalValue + item.price) >= 999999) return;

        setCartItems((prevItems) => {
            const updatedItems = prevItems.map((cartItem) => {
                if (
                    cartItem.title === item.title &&
                    areVariantsEqual(cartItem.selectedOptions, item.selectedOptions)
                ) {
                    if (cartItem.quantity < cartItem.stock) {
                        return {
                            ...cartItem,
                            quantity: cartItem.quantity + 1,
                        };
                    } else {
                        console.warn("Stock limit reached for item:", cartItem);
                    }
                }
                return cartItem;
            });
            return updatedItems;
        });
    };

    const decreaseQuantity = (item) => {
        setCartItems((prevItems) =>
            prevItems
                .map((cartItem) =>
                    cartItem.title === item.title &&
                    areVariantsEqual(cartItem.selectedOptions, item.selectedOptions) // Match variants
                        ? { ...cartItem, quantity: cartItem.quantity - 1 }
                        : cartItem
                )
                .filter((cartItem) => cartItem.quantity > 0) // Remove items with 0 quantity
        );
    };

    const closeDrawer = () => setIsDrawerOpen(false);

    return (
        <CartContext.Provider
            value={{
                cartItems,
                totalValue,
                addToCart,
                isDrawerOpen,
                closeDrawer,
                increaseQuantity,
                decreaseQuantity,
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
